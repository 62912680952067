<template>
  <div class="content">
    <b-navbar toggleable="md" type="light" variant="light" class="navbar-fixed-top navUser">
      <b-navbar-brand to="/">
        <img src="@/assets/svg/logo-v2.svg" alt="EZEZJOB">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="p-2" to="/jobs">Jobs</b-nav-item>
          <b-nav-item class="p-2" to="/companies">Company</b-nav-item>
          <b-nav-item class="p-2" to="/login" v-if="!user">Login</b-nav-item>
          <b-nav-item v-else class="pr-2" toggleable="md">
            <b-nav-item-dropdown right no-caret >
              <template #button-content>
                <b-avatar  :src="avatar" size="1.6em"></b-avatar>
              </template>
              <MenuUser :user="user"/>
            </b-nav-item-dropdown>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="app">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import MenuUser from "@/components/MenuUser.vue";
export default {
  components: {
    Footer,
    MenuUser
  },
  created() {
    const user = localStorage.getItem("user_info") && JSON.parse(localStorage.getItem("user_info"))
    if(user){
      this.avatar = user.avatar
      this.user = user
    }
  },
  data() {
    return {
      avatar: null,
      name: "",
      user: null
    }
  }
};
</script>

<style lang="scss" scoped>
// .container {
//   @media (min-width: 1278px){
//     max-width: 1278px !important;
//   }
// }
.content {
  .navUser {
    background: #ffffff !important;
    box-shadow: 1px 1px 10px #00000029;
    padding: 0.25rem 1rem;
  }
  .navbar-nav .nav-link {
    color: #000000 !important;
  }
  .padding {
    padding: .5em;
  }
  .navbar-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .app {
    margin-top: 3em;
  }
}
</style>