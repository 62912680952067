<template>
<div class="line" :style="cssVars" ></div>  
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#53A2DA"
    },
    height: {
      type: Number,
      default: 3
    },
    m: {
      type: Number,
      default: 1
    }
  },
  computed: {
    cssVars() {
      return {
        '--bg-color': this.color,
        '--height': `${this.height}px`,
        '--margin': `${this.m}rem`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@supports (color: var(--prop)) {
  .line {
    width: 100%;
    height: var(--height);
    background-color: var(--bg-color);
    border-radius: 25px;
    margin: var(--margin) 0;
  }
}
</style>