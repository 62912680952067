<template>
  <div class="card h-100" >
    <div class="card-head">
      <b-avatar :src="user.avatar" class="avatar"></b-avatar>
    </div>
    <div class="card-body">
        <div class="card-title">
          <h5 class="m-0">{{ user.company ? user.company.name.th : user.fullname }}</h5>
          <p>{{ user.email }}</p>
          <b-dropdown-divider class="pr-4 pl-4" />
        </div>
        <div v-if="user.company">
          <b-dropdown-item>
            <router-link to="/companyprofile" class="d-flex justify-content-between">
              <p>Company Profile </p>
              <p><b-icon icon="chevron-right"></b-icon></p>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/createjob" class="d-flex justify-content-between">
              <p>Post Job</p>
              <p><b-icon icon="chevron-right"></b-icon></p>
            </router-link>
          </b-dropdown-item>
        </div>
        <div v-else>
          <b-dropdown-item>
            <router-link to="/" class="d-flex justify-content-between">
              <p>Profile</p>
              <p><b-icon icon="chevron-right"></b-icon></p>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/" class="d-flex justify-content-between">
              <p>My Resume</p>
              <p><b-icon icon="chevron-right"></b-icon></p>
            </router-link>
          </b-dropdown-item>
        </div>
        <b-dropdown-item>
          <router-link to="/" class="d-flex justify-content-between">
            <p>Change Password</p>
            <p><b-icon icon="chevron-right"></b-icon></p>
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item @click="logout()">
          <div  class="d-flex justify-content-between">
            <p>Logout</p>
            <p><b-icon icon="chevron-right"></b-icon></p>
          </div>
        </b-dropdown-item>
    </div>
  </div>
</template>
<script>
import { removeToken } from "@/services/auth.service";

export default {
  
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
  },
  methods: {
    logout() {
      removeToken()
       window.location.href = '/';
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  padding: 0;
  border-radius: 0.5rem !important;
  width: 15rem;
  a {
    p {
      font-size: 14px;
      color: black;
      margin: 0.25rem;
    }
    &:focus, &:hover {
      color: black;
      text-decoration: none;
    }
  }
  .card-head {
    position: relative;
    background: $primary-color;
    height: 4rem;
    border-radius: .5rem .5rem 0 0;
    .avatar {
      width: 4em;
      height: 4em;
      left: 5.5rem;
      top: 2rem;
      box-shadow: 1px 1px 10px #888888;
      border: 2px white solid;
    }
    .card-img-logo {
      position: absolute;
      left: 3em;
      bottom: -15px;
      width: 25%;
      box-shadow: 1px 1px 10px #888888;
      border-radius: 50px;
    }
    .card-img-logo-second {
      margin: 1em;
      width: 25%;
      box-shadow: 1px 1px 10px #888888;
    }
  }
  .card-body {
    padding: 0;
    .card-title {
      font-size: 1.2rem;
      margin: 2rem 0 0;
      text-align: center;
      p {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
  
</style>