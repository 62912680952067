import Cookies from "js-cookie";
import ApiService from "./api.service";
const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const domain = process.env.NODE_ENV === "production" ? "www.ezezjob.com" : "localhost";

const COOKIE_OPTION = {
  domain,
  expires: 90,
  secure: process.env.NODE_ENV === "production" ? true : false
};

export const getToken = () => {
  return Cookies.get(TOKEN_KEY, COOKIE_OPTION);
};

export const saveToken = (tokenType, accessToken) => {
  const token = `${tokenType} ${accessToken}`;
  console.log({ token })
  Cookies.set(TOKEN_KEY, token, COOKIE_OPTION);
};
export const saveRefreshToken = refreshToken => {
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken, COOKIE_OPTION);
};
export const removeToken = () => {
  Cookies.remove(TOKEN_KEY, { domain: COOKIE_OPTION.domain });
  Cookies.remove(REFRESH_TOKEN_KEY, { domain: COOKIE_OPTION.domain });
  localStorage.removeItem("user_info")
};

export const authLoginUser = async payload => {
  const response =  await ApiService.post("/auth/login", payload);
  if (response.data) {
    const { data: { token_type, access_token, refresh_token, user_info } } = response;
    saveToken(token_type, access_token)
    saveRefreshToken(refresh_token)
    localStorage.setItem("user_info", JSON.stringify(user_info))
  }
  return response
};

export const createSeeker = async (payload) => {
  const response =  await ApiService.post("/auth/register", payload);
  return response
}