import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, LayoutPlugin, CardPlugin, IconsPlugin } from 'bootstrap-vue'
import Colxx from './components/commons/Colxx'
import "./assets/scss/app.scss";
import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";
import vuetify from './plugins/vuetify'

const toastOptions = {
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);
Vue.use(CardPlugin);
Vue.use(Toast, toastOptions);
Vue.use(vuetify);
Vue.use(IconsPlugin);

Vue.config.productionTip = false
Vue.component('b-colxx', Colxx);
Vue.use(require('vue-shortkey'));

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
