import { render, staticRenderFns } from "./Divider.vue?vue&type=template&id=bed2acbc&scoped=true&"
import script from "./Divider.vue?vue&type=script&lang=js&"
export * from "./Divider.vue?vue&type=script&lang=js&"
import style0 from "./Divider.vue?vue&type=style&index=0&id=bed2acbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bed2acbc",
  null
  
)

export default component.exports