import axios from "axios";
import * as AuthService from "./auth.service";

const HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Requested-With": "XMLHttpRequest",
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: HEADERS,
});

instance.interceptors.request.use(
  async (config) => {
    const token = AuthService.getToken();
    config.headers.Authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return { data: res.data };
  },
  (error) => {
    const errorResponse = {
      error,
      code: error.response.data.statusCode,
    };
    if (typeof error.response.data.message === "string") {
      errorResponse.message = error.response.data.message;
    } else if (typeof error.response.data.message === "object") {
      errorResponse.validation = error.response.data.message;
    }
    return errorResponse;
  }
);

const apiService = {
  init(baseURL) {
    instance.defaults.baseURL = baseURL;
  },

  get(resource, data = null) {
    return instance.get(resource, data);
  },

  post(resource, data) {
    return instance.post(resource, data);
  },

  patch(resource, data) {
    return instance.patch(resource, data);
  },

  put(resource, data) {
    return instance.put(resource, data);
  },

  delete(resource, data) {
    return instance.delete(resource, data);
  },
};

export default apiService;
