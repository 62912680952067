import Vue from "vue";
import VueRouter from "vue-router";
import Default from "@/layouts/Default.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Default,
    children: [
      {
        path: "",
        name: "LandingPage",
        component: () =>
          import(`@/views/LandingPage.vue`),
      },
      {
        path: "/companies",
        name: "Companies",
        component: () =>
          import(`@/views/company/Companies.vue`),
      },
      {
        path: "/companies/:id/jobs",
        name: "CompanyDetail",
        component: () =>
          import(`@/views/company/CompanyDetail.vue`),
      },
      {
        path: "/createcompany",
        name: "CreateCompany",
        component: () =>
          import(`@/views/company/CreateCompany.vue`),
      },
      {
        path: "/updatecompany",
        name: "UpdateCompany",
        component: () =>
          import(`@/views/company/CreateCompany.vue`),
      },
      {
        path: "/companyprofile",
        name: "CompanyProfile",
        component: () =>
          import(`@/views/company/CompanyProfile.vue`),
      },
      {
        path: "/jobs",
        name: "Jobs",
        component: () =>
          import(`@/views/job/Jobs.vue`),
      },
      {
        path: "/detailjobs/:id",
        name: "JobDetail",
        component: () =>
          import(`@/views/job/JobDetail.vue`),
      },
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(`@/views/auth/Login.vue`),
      },
      {
        path: "/register",
        name: "Register",
        component: () =>
          import(`@/views/auth/Register.vue`),
      },
      {
        path: "/createuser",
        name: "CreateUser",
        component: () =>
          import(`@/views/auth/CreateUser.vue`),
      },
      {
        path: "/privacy-policy",
        name: "Policy",
        component: () =>
          import(`@/views/Policy.vue`),
        meta: { title: "Policy" }
      },
      {
        path: "/createjob",
        name: "CreateJob",
        component: () =>
          import(`@/views/job/CreateJob.vue`),
        meta: { title: "CreateJob" }
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
