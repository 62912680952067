<template>
<section>
  <b-container class="container">
    <p class="ezezJob"> <span class="copyright">© </span> ezezJob.com</p>
    <divider color="#5C5C5C" :height="2" :m="0"/>
    <b-row>
      <b-col md="4" lg="4" sm="12">
        <img src="@/assets/svg/logo-gray.svg" alt="ezezjob" class="mb-3 mt-3">
        <b-row style="margin-right: 8rem;">
          <b-col class="imge-icon">
            <img src="@/assets/svg/fb-icon.svg" alt="ezezjob">
          </b-col>
          <b-col class="imge-icon">
            <img src="@/assets/svg/tw-icon.svg" alt="ezezjob">
          </b-col>
          <b-col class="imge-icon">
            <img src="@/assets/svg/ig-icon.svg" alt="ezezjob">
          </b-col>
          <b-col class="imge-icon">
            <img src="@/assets/svg/yt-icon.svg" alt="ezezjob">
          </b-col>
          <b-col class="imge-icon">
            <img src="@/assets/svg/rd-icon.svg" alt="ezezjob">
          </b-col>
        </b-row>
      </b-col>
      <b-col md="2" lg="2" sm="12" class="mt-2">
        <p class="head">ezezJob</p>
        <a href="/" class="link">Home</a> <br>
        <a href="/privacy-policy" class="link">Privacy Policies</a>
      </b-col>
      <b-col md="2" lg="2" sm="12" class="mt-2">
        <p class="head">For</p>
        <a href="/" class="link">Company</a> <br>
        <a href="/" class="link">Seeker</a>
      </b-col>
      <b-col md="2" lg="2" sm="12" class="mt-2">
        <p class="head">Resources</p>
        <a href="/" class="link">Blog</a> <br>
      </b-col>
      <b-col md="2" lg="2" sm="12" class="mt-2">
        <p class="head">Company</p>
        <a href="/" class="link">About</a> <br>
      </b-col>
    </b-row>
  </b-container>
</section>
  
</template>

<script>
import Divider from "@/components/Divider.vue";
export default {
  components: {
    Divider
  },
}
</script>

<style lang="scss" scoped>
section {
  background: #F7F7F8;
  margin-top: 5rem;
  .imge-icon {
    padding: 0;
  }
  .container {
    padding-bottom: 5rem;
    .row {
      margin-left: 0 !important;
    }
    .ezezJob {
      span.copyright {
        font-family: sans-serif;
        font-size: 1.8rem;
        margin-right: .5rem !important;
      }
      font-weight: 400 !important;
      padding: 2rem 0 0;
      font-size: 1.2rem !important;
      color: #8D9093;
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }
  }
  .head {
    color: #8D9093;
    font-size: 1.2rem;
    font-weight: 400 !important;
  }
  .link {
    font-size: 1rem;
    color: #000000;
    border-bottom: 1px solid black;
    &:active, &:hover {
      color: #8D9093;
      text-decoration: none;
    }
  }
}

</style>